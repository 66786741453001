/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { CssBaseline, Typography } from "@material-ui/core";

//  import Header from "./header"
//  import Footer from "./footer"

import { create } from "jss";
import rtl from "jss-rtl";
import {
  StylesProvider,
  jssPreset,
  createMuiTheme,
  ThemeProvider,
} from "@material-ui/core/styles";

import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import WhatshotIcon from '@material-ui/icons/Whatshot';

import Fire from "./fire"

import CairoWoff2 from "../../static/fonts/SLXGc1nY6HkvalIkTpu0xg.woff2";

import { Link } from "gatsby"


const cairo = {
  fontFamily: "Cairo",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
     local('Cairo'),
     local('Cairo-Regular'),
     url(${CairoWoff2}) format('woff2')
   `,
  unicodeRange:
    "U+0600-06FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE80-FEFC",
};

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const theme = createMuiTheme({
  direction: "rtl",
  typography: {
    fontFamily: "Cairo, Arial",
  },
  palette: {
    primary: {
      main: `#b71c1c`, // or whatever colors you need
      type: "dark",
    },
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [cairo],
      },
    },
  },
});

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginRight: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
      padding: 15,
      textAlign: "center",
      fontWeight: 900,
      color: "#fff",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  header: {
    fontSize: "20px",
    color: "#FFF",
    padding: 15,
    textAlign: "center",
    marginTop: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#111",
    background: "linear-gradient(to bottom, #555, #111)",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  menuList: {
    "&>a, &>button": {
      fill: "#FFF",
      color: "#FFF",
    },
    "& .MuiListItemText-root": {
      marginRight: 10
    }
  },
  pageTitle: {
    textAlign: 'center',
    width: '100%'
  },
  menuItem: {
    '&.MuiListItem-root.Mui-selected': {
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
    }
  }
}));

// const useStyles = makeStyles(theme => ({

// }))

function ListItemLink(props) {

  const classes = useStyles();

  const { primary, to, id, mSelected } = props

  const renderLink = React.useMemo(
    () =>
      React.forwardRef((itemProps, ref) => (
        <Link to={to} ref={ref} {...itemProps} />
      )),
    [to]
  )

  return (
    <ListItem button component={renderLink} selected={mSelected === id} className={classes.menuItem}>
      <WhatshotIcon />
      <ListItemText primary={primary} />
    </ListItem>
  )
}

const Layout = ({ children, pageTitle = "السباق", window, menu = [], selected }) => {
  const classes = useStyles();

  // console.log(menu);
  //   const theme = useTheme();

  //   const {  } = props;

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div style={{height: '100%'}}>
      <div className={classes.toolbar}>
        <h1 className={classes.header}>السباق الأكبر</h1>
      </div>
      <Divider />
      <List className={classes.menuList}>
        {menu.map(({id, text}) => (
          <ListItemLink key={id} id={id} mSelected={selected} primary={text} to={`/competitions/${id}`} />
        ))}
      </List>
      <Fire />
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      <ThemeProvider theme={theme}>
        <StylesProvider jss={jss}>
          <CssBaseline />
          <div className={classes.root}>
            <AppBar position="fixed" className={classes.appBar}>
              <Toolbar>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  className={classes.menuButton}
                >
                  <MenuIcon />
                </IconButton>
                <Typography variant="h6" noWrap className={classes.pageTitle}>
                  {pageTitle}
                </Typography>
              </Toolbar>
            </AppBar>
            <nav className={classes.drawer} aria-label="mailbox folders">
              {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
              <Hidden smUp implementation="css">
                <Drawer
                  container={container}
                  variant="temporary"
                  open={mobileOpen}
                  onClose={handleDrawerToggle}
                  classes={{
                    paper: classes.drawerPaper,
                  }}
                  ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                  }}
                >
                  {drawer}
                </Drawer>
              </Hidden>
              <Hidden xsDown implementation="css">
                <Drawer
                  classes={{
                    paper: classes.drawerPaper,
                  }}
                  variant="permanent"
                  open
                >
                  {drawer}
                </Drawer>
              </Hidden>
            </nav>
            <main className={classes.content}>
              <div className={classes.toolbar} />
              {children}
            </main>
          </div>
        </StylesProvider>
      </ThemeProvider>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  // langLinks: PropTypes.array.isRequired
};

export default Layout;
