import React, { useState, useEffect } from "react";
import {
  Typography,
  makeStyles,
  Grid,
  Card,
  CardMedia,
  CardActionArea,
  CardContent,
  Container,
  LinearProgress,
  Box,
  Button,
} from "@material-ui/core";
import Layout from "../components/layout";
import SEO from "../components/seo";
import WebotakPro from "../components/webotakPro";

import FingerprintJS from "@fingerprintjs/fingerprintjs";

import base from "../components/rebase";

const fpPromise = FingerprintJS.load();

// import WhatshotIcon from '@material-ui/icons/Whatshot';

const competitions = require("../../competitions.json");

const _ = require("lodash");

const useStyles = makeStyles((theme) => ({
  mainImage: {
    borderRadius: 5,
    width: 250,
    margin: "20px auto",
  },
  mainImageWrap: {
    textAlign: "center",
    width: "100%",
  },
  card: {
    boxShadow: "0px 3px 6px rgb(0 0 0 / 10%)",
    transition: "all .5s ease",
  },
  cardSelected: {
    backgroundColor: "#80e27e",
    boxShadow: "0px 3px 6px rgb(0 0 0 / 10%)",
    transition: "all .5s ease",
  },
  media: {
    height: 160,
  },
  mainGrid: {
    marginTop: 15,
  },
  progress: {
    marginTop: 25,
    height: 23,
    borderRadius: 20,
    "&.MuiLinearProgress-colorPrimary": {
      backgroundColor: "rgb(245 104 0)",
    },
  },
  leftNumber: {
    position: "absolute",
    left: 10,
    top: 25,
    color: "#FFF",
    zIndex: 999,
  },
  rightNumber: {
    position: "absolute",
    right: 10,
    bottom: 0,
    color: "#FFF",
    zIndex: 999,
  },
  adCard: {
    marginTop: 20,
  },
  adName: {
    padding: "7px 14px",
    position: "relative",
    top: "-5px",
    border: "1px solid #ccc",
    color: "#ccc",
    borderRadius: 3,
  },
}));

function IndexPage({ data, pageContext }) {
  const classes = useStyles();

  const { id, text, description, image, options } = pageContext;

  const windowGlobal = typeof window !== "undefined" && window;

  const initState =
    windowGlobal && windowGlobal.localStorage.getItem(`vote-${id}`)
      ? windowGlobal.localStorage.getItem(`vote-${id}`)
      : null;

  const initFingerPrint =
    windowGlobal && windowGlobal.localStorage.getItem(`fingerPrint`)
      ? windowGlobal.localStorage.getItem(`fingerPrint`)
      : null;

  const title = text ? text : "الصفحة الرئيسية";

  const [selected, setSelected] = useState(parseInt(initState));

  const [votes, setVotes] = useState({});

  function fetchData() {
    base.listenTo(`votes/challenge-${id}`, {
      context: pageContext,
      asArray: false,
      then(data) {
        const dataVotes = Object.keys(data).map((key) => data[key].itemId);
        let ones = 0,
          twos = 0;
        for (const vote of dataVotes) {
          if (vote === 1) {
            ones++;
          } else {
            twos++;
          }
        }
        setVotes({ ones, twos });

        // setVotes(  )
      },
    });
  }

  useEffect(() => {
    fetchData();
  }, []);

  const totalV = votes.ones + votes.twos;

  const putVote = async (itemId) => {
    let fingerPrint = "";
    if (initFingerPrint) {
      fingerPrint = initFingerPrint;
    } else {
      const fp = await fpPromise;
      const result = await fp.get();

      fingerPrint = result.visitorId;

      windowGlobal.localStorage.setItem(`fingerPrint`, fingerPrint);
    }

    base.update(`votes/challenge-${id}/${fingerPrint}`, {
      data: { itemId },
      then(err) {
        if (!err) {
          windowGlobal.localStorage.setItem(`vote-${id}`, itemId);
          setSelected(itemId);
        }
      },
    });
  };

  console.log({ options, selected });

  return (
    <Layout
      pageTitle={title}
      menu={competitions.map((item) => _.pick(item, ["id", "text"]))}
      selected={id}
    >
      <SEO title={title} />

      {!id && <p>Home !</p>}

      {id && (
        <Container maxWidth="md">
          <div className={classes.mainImageWrap}>
            <img className={classes.mainImage} src={image} alt="Challenge" />
          </div>
          <Typography variant="h6">{description}</Typography>
          <Grid container spacing={2} className={classes.mainGrid}>
            {options && windowGlobal &&
              options.map((item) => (
                <Grid key={item.id} item xs={6}>
                  <Card
                    className={
                      selected === item.id ? classes.cardSelected : classes.card
                    }
                  >
                    <CardActionArea onClick={() => putVote(item.id)}>
                      <CardMedia
                        className={classes.media}
                        image={item.image}
                        title={item.text}
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h6" component="h2">
                          {item.text}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              ))}
          </Grid>

          <Box
            display="flex"
            alignItems="center"
            style={{ position: "relative" }}
          >
            <Typography className={classes.rightNumber}>
              {votes.ones}
            </Typography>
            <Box width="100%">
              <LinearProgress
                variant="determinate"
                value={(100 * votes.ones) / totalV}
                className={classes.progress}
              />
            </Box>
            <Typography className={classes.leftNumber}>{votes.twos}</Typography>
          </Box>
          <WebotakPro>
            <Typography
              className={classes.adName}
              gutterBottom
              component="span"
            >
              إعلان
            </Typography>
            <Typography variant="h5" component="h2" style={{ marginTop: 10 }}>
              أقوى عرض لتمتلك به موقعك الخاص
            </Typography>
            <Typography style={{ margin: "15px 10px" }}>
              تكلفة الموقع 90$
              <br /> + هدية استضافة ودومين لمدة عام مجانا
            </Typography>
            <Typography
              variant="body2"
              component="p"
              style={{ marginRight: 10 }}
            >
              تجديد استضافة + دومين 28$ سنويا فقط
              <br />
            </Typography>
            <Button
              variant="contained"
              component="a"
              href="https://api.whatsapp.com/send?phone=905315044501&text=%D9%85%D8%B1%D8%AD%D8%A8%D8%A7+%2C+%D8%A3%D8%B1%D9%8A%D8%AF+%D8%B9%D9%86+%D8%A7%D8%B3%D8%AA%D9%81%D8%B3%D8%B1+%D8%B9%D9%86+%D8%B9%D8%B1%D8%B6+%D8%A7%D9%84+90%24"
              style={{
                backgroundColor: "#1b5e20",
                fontWeight: 600,
                color: "#FFF",
                marginTop: 15,
              }}
            >
              تواصل معنا لمعرفة المزيد
            </Button>
          </WebotakPro>
          {/* <Card className={classes.adCard} variant="outlined">
            <CardContent>
            </CardContent>
            <CardActions>
            </CardActions>
          </Card> */}
        </Container>
      )}
    </Layout>
  );
}

export default IndexPage;
