import Rebase from 're-base';
import firebase from 'firebase/app';
import 'firebase/database';

var app = firebase.initializeApp({
    apiKey: "AIzaSyD0iJnHjJdHuzGRe4j34j-_egIBuXGbGp4",
    authDomain: "compoetisions.firebaseapp.com",
    databaseURL: "https://compoetisions-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "compoetisions",
    storageBucket: "compoetisions.appspot.com",
    messagingSenderId: "770334708233",
    appId: "1:770334708233:web:ef4ebde9054f5f469b5ed8",
    measurementId: "G-55326LT1M1"
});

var db = firebase.database(app);
var base = Rebase.createClass(db);

export default base;