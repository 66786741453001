/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import Helmet from "react-helmet"

function SEO({ title = "السباق" }) {

  const metaDescription = 'موقع للمسابقات الغير هادفة'

  let lang = "ar"
  let dir = { dir: "rtl" }

  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      bodyAttributes={dir}
      title={title}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: 'Webotak : Moutaz Alhomsi',
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: 'og:image',
          content: '/3831021.jpg'
        }
      ]}
    >
      <link rel="icon" type="image/png" href={'/cropped-favicon.png'} />
    </Helmet>
  )
}

export default SEO
