import React from "react";

import "./webotak-pro.css";

export default function WebotakPro({ children }) {
  return (
    <div className="block">
      <a href="#">
        <span className="spano"></span>
      </a>
      <div
        style={{
          zIndex: 999999999,
          position: "absolute",
          color: "#fff",
          padding: 20,
        }}
      >
        {children}
      </div>
    </div>
  );
}
